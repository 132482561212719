import { Contact } from '../../../api/Contacts';
import handleOnChange from '../../../functions/handleOnChange';
import handleRadioChange from '../../../functions/handleRadioChange';
import { BsPlusCircleDotted } from 'react-icons/bs';

export default ({ dataState }: { dataState: [Contact, React.Dispatch<React.SetStateAction<Contact>>] }) => {
	// Use redux to pass data down to components
	const [data, setData] = dataState;

	return (
		<>
			<div className="w-full">
				{/* <h3>{data?.name?.first ? 'Edit ' + data?.name.first : 'Add a contact'}</h3> */}
				<div className="p-2 w-full">
					<div className="flex flex-wrap flex-row">
						<div className=" w-1/4 p-2">
							<label htmlFor="first_name" className=" required">
								First Name
							</label>
							<input
								type="text"
								value={data && 'name' in data ? data.name?.first : ''}
								onChange={(e) => {
									handleOnChange(e, ['name', 'first'], setData);
								}}
								className=""
								id="first_name"
								autoFocus
								required
							/>
							{/* <p className="small_help">Used for [CONTACT_FIRST_NAME] smartfields. </p> */}
							{/* <div className="invalid-feedback">Please add a first name</div> */}
						</div>
						<div className="w-1/4 p-2">
							<label htmlFor="last_name" className="">
								Last Name
							</label>
							<input
								type="text"
								value={data && 'name' in data ? data.name?.last : ''}
								onChange={(e) => {
									handleOnChange(e, ['name', 'last'], setData);
								}}
								className=""
								id="last_name"
							/>
							{/* <p className="small_help">Used for [CONTACT_LAST_NAME] smartfields. </p> */}
						</div>
						<div className="w-1/4 p-2">
							<label htmlFor="email" className=" required">
								Email
							</label>
							<input
								type="email"
								value={data?.email}
								onChange={(e) => {
									handleOnChange(e, ['email'], setData);
								}}
								className=""
								id="email"
								required
							/>
							{/* <div className="invalid-feedback">Please enter a valid email address</div> */}
						</div>
						<div className="w-1/4 p-2">
							<label htmlFor="mobile" className="">
								Phone
							</label>
							<input
								type="text"
								value={data?.mobile}
								onChange={(e) => {
									handleOnChange(e, ['mobile'], setData);
								}}
								className=""
								id="mobile"
							/>
						</div>
						<div className=" w-1/6 p-2">
							<label htmlFor="relationship" className="">
								Relationship
							</label>
							<select
								id="relationship"
								className="form-select form-selec"
								onChange={(e) => {
									handleOnChange(e, ['relationship'], setData);
								}}
							>
								<option value={0} selected={data?.relationship === 0 || data?.relationship === undefined}>
									None
								</option>
								<option value={1} selected={data?.relationship === 1}>
									Lead
								</option>
								<option value={2} selected={data?.relationship === 2}>
									Customer
								</option>
								<option value={3} selected={data?.relationship === 3}>
									Connection
								</option>
							</select>
						</div>
						<div className=" w-1/6 p-2">
							<label htmlFor="source" className="">
								Source
							</label>
							<select
								id="source"
								className="form-select form-selec"
								onChange={(e) => {
									handleOnChange(e, ['source'], setData);
								}}
							>
								<option value={'0'} selected={data?.source === '0' || data?.source === '0'}>
									None
								</option>
								<option value={'1'} selected={data?.source === '1'}>
									Google Ads
								</option>
								<option value={'2'} selected={data?.source === '2'}>
									Facebook Ads
								</option>
								<option value={'3'} selected={data?.source === '3'}>
									Social Media
								</option>
								<option value={'4'} selected={data?.source === '4'}>
									Referral Partner
								</option>
								<option value={'5'} selected={data?.source === '5'}>
									Bconnected Networking
								</option>
							</select>
						</div>
						<div className="w-1/6 p-2">
							<label htmlFor="inputDOB" className="">
								Date of Birth
							</label>
							<input
								type="date"
								value={data && 'dob' in data ? data.dob : ''}
								onChange={(e) => {
									handleOnChange(e, ['dob'], setData);
								}}
								className=""
								id="inputDOB"
							/>
						</div>
						<div className="w-1/4 p-2">
							<p className="form-label">Gender</p>
							{/* {/* <input type="text" value={data?.gender} onChange={(e) => {handleOnChange(e, 'gender')}} className="" id="inputGender" placeholder="1234 Main St" /> *, setData/} */}
							<div className="form-check p-2 inline">
								<input
									className="form-check-input mx-1"
									checked={
										(data && 'gender' in data && data.gender === 0) ||
										(data && 'gender' in data && data.gender === undefined)
									}
									onChange={(e) => {
										handleRadioChange(e, ['gender'], setData);
									}}
									type="radio"
									name="inputGender"
									id="InputGenderOther"
									value={0}
								/>
								<label className="form-check-label" htmlFor="InputGenderOther">
									Other
								</label>
							</div>
							<div className="form-check p-2 inline">
								<input
									className="form-check-input mx-1"
									checked={data && 'gender' in data && data.gender === 1}
									onChange={(e) => {
										handleRadioChange(e, ['gender'], setData);
									}}
									type="radio"
									name="inputGender"
									id="InputGenderMale"
									value={1}
								/>
								<label className="form-check-label" htmlFor="InputGenderMale">
									Male
								</label>
							</div>
							<div className="form-check p-2 inline">
								<input
									className="form-check-input mx-1"
									checked={data && 'gender' in data && data.gender === 2}
									onChange={(e) => {
										handleRadioChange(e, ['gender'], setData);
									}}
									type="radio"
									name="inputGender"
									id="InputGenderFemale"
									value={2}
								/>
								<label className="form-check-label" htmlFor="InputGenderFemale">
									Female
								</label>
							</div>
							{/* <p className="small_help">Used for [THEY_HE_SHE] and [THEM_HIM_HER] smartfields.</p> */}
						</div>
						<div className=" w-full flex flex-wrap flex-row">
							{' '}
							{/* Address section */}
							<div className="w-1/6 p-2">
								<label htmlFor="inputAddress" className="">
									Country
								</label>
								<input
									type="text"
									value={data?.address?.country}
									onChange={(e) => {
										handleOnChange(e, ['address', 'country'], setData);
									}}
									className=""
									id="inputAddress"
									placeholder="Australia"
								/>
							</div>
							<div className="w-1/6 p-2">
								<label htmlFor="inputAddress" className="">
									Address
								</label>
								<input
									type="text"
									value={data?.address?.street}
									onChange={(e) => {
										handleOnChange(e, ['address', 'street'], setData);
									}}
									className=""
									id="inputAddress"
									placeholder="1234 Main St"
								/>
							</div>
							{/* <div className="w-1/6 p-2">
								<label htmlFor="inputAddress2" className="">
									Address 2
								</label>
								<input
									type="text"
									value={data?.address_2}
									onChange={(e) => {
										handleOnChange(e, 'address_2', setData);
									}}
									className=""
									id="inputAddress2"
									placeholder="Apartment, studio, or floor"
								/>
							</div> */}
							<div className="w-1/6 p-2">
								<label htmlFor="inputCity" className="">
									City
								</label>
								<input
									type="text"
									value={data?.address?.city}
									onChange={(e) => {
										handleOnChange(e, ['address', 'city'], setData);
									}}
									className=""
									id="inputCity"
								/>
							</div>
							<div className="w-1/6 p-2">
								<label htmlFor="inputState" className="">
									State
								</label>
								<input
									type="text"
									value={data?.address?.state}
									onChange={(e) => {
										handleOnChange(e, ['address', 'state'], setData);
									}}
									className=""
									id="inputState"
								/>
								{/* <select id="inputState" className="form-select form-selec">
                        <option selected disabled value={0}>Choose...</option>
                        <option value={0}>ACT</option>
                        <option value={1}>NSW</option>
                        <option value={2}>NT</option>
                        <option value={3}>QLD</option>
                        <option value={4}>SA</option>
                        <option value={5}>TAS</option>
                        <option value={6}>VIC</option>
                        <option value={7}>WA</option>
                        </select> */}
							</div>
							<div className="w-1/6 p-2">
								<label htmlFor="inputZip" className="">
									Zipcode
								</label>
								<input
									type="text"
									value={data?.address?.postcode}
									onChange={(e) => {
										handleOnChange(e, ['address', 'zipcode'], setData);
									}}
									className=""
									id="inputZip"
								/>
							</div>
						</div>
						<div className=" w-5/12 p-2">
							<label htmlFor="personal_notes" className="">
								Summary
							</label>

							<textarea
								// value={data?.personal_notes}
								rows={12}
								// onChange={(e) => {
								// 	handleOnChange(e, 'personal_notes', setData);
								// }}
								className=""
								// id="personal_notes"
							></textarea>
							<p className="small_help">
								Private note only users in your company can see. This message will be displayed as a reminder of who your
								client is.
							</p>
						</div>

						{/* <button type="submit" className=" bg-emerald-500 hover:bg-emerald-600" disabled={submitLock}>
								{submitLock ? 'Loading...' : (data && 'name' in data ? data.name?.first : '') ? 'Save ' : 'Add'}
							</button> */}
						{/* <button
								type="button"
								onClick={() => dispatch(update({ state: STATE_DASHBOARD, _id: '' }))}
								className=" bg-red-500 hover:bg-red-600"
							>
								Back
							</button> */}
					</div>
				</div>
				<br />
				<h3 className="mt-8">Social and other accounts</h3>
				<div className="p-2 rounded-lg w-full ">
					<div className="border-2 border-slate-300 bg-stone-100 py-12 px-8 cursor-pointer w-fit inline-block mx-2">
						<BsPlusCircleDotted size={24} />
					</div>
					{/* <div className="border-2 border-slate-300 bg-stone-100 py-12 px-8 cursor-pointer w-fit inline-block mx-2">
						<BsPlusCircleDotted size={24} />
					</div>
					<div className="border-2 border-slate-300 bg-stone-100 py-12 px-8 cursor-pointer w-fit inline-block mx-2">
						<BsPlusCircleDotted size={24} />
					</div>
					<div className="border-2 border-slate-300 bg-stone-100 py-12 px-8 cursor-pointer w-fit inline-block mx-2">
						<BsPlusCircleDotted size={24} />
					</div> */}

					{/* <div className="flex flex-wrap flex-row w-auto ">
						<div className="w-full p-2">
							<label htmlFor="title" className="">
								Website
							</label>
							<input
								type="text"
								value={undefined}
								onChange={(e) => {
									handleOnChange(e, [''], setData);
								}}
								className=""
								id="contact-website"
							/>
						</div>
						<div className="w-full p-2">
							<label htmlFor="title" className="">
								Facebook
							</label>
							<input
								type="text"
								value={undefined}
								onChange={(e) => {
									handleOnChange(e, [''], setData);
								}}
								className=""
								id="contact-Facebook"
							/>
						</div>
						<div className="w-full p-2">
							<label htmlFor="title" className="">
								Instagram
							</label>
							<input
								type="text"
								value={undefined}
								onChange={(e) => {
									handleOnChange(e, [''], setData);
								}}
								className=""
								id="contact-instagram"
							/>
						</div>
						<div className="w-full p-2">
							<label htmlFor="title" className="">
								Twitter
							</label>
							<input
								type="text"
								value={undefined}
								onChange={(e) => {
									handleOnChange(e, [''], setData);
								}}
								className=""
								id="contact-twitter"
							/>
						</div>
						<div className="w-full p-2">
							<label htmlFor="title" className="">
								Tiktok
							</label>
							<input
								type="text"
								value={undefined}
								onChange={(e) => {
									handleOnChange(e, [''], setData);
								}}
								className=""
								id="contact-tiktok"
							/>
						</div>
						<div className="w-full p-2">
							<label htmlFor="title" className="">
								LinkedIn
							</label>
							<input
								type="text"
								value={undefined}
								onChange={(e) => {
									handleOnChange(e, [''], setData);
								}}
								className=""
								id="contact-linkedin"
							/>
						</div>
						<div className="w-full p-2">
							<label htmlFor="title" className="">
								Other
							</label>
							<input
								type="text"
								value={undefined}
								onChange={(e) => {
									handleOnChange(e, [''], setData);
								}}
								className=""
								id="contact-other"
							/>
						</div>
						<div>click to open a modal so the user can choose the Label, [SHORTCODE]</div>
						<button type="button">Add another</button> 
					</div> */}
				</div>
			</div>
		</>
	);
};
