import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { STATE_DASHBOARD } from '../../functions/Constants';
import { useToast } from '../../components/toast/ToastService';
import { getAllContactsArray, newMailerObject } from '../../functions/CommonFunctions';
import { Select, SelectOption } from '../../components/Select';
import handleOnChange from '../../functions/handleOnChange';

import { BaseEditor, createEditor } from 'slate';
import { ReactEditor, Slate, withReact } from 'slate-react';
import RichEditor, { CustomElement } from '../../components/RichEditor';
import Modal from '../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { update } from '../../state/viewSlice';

export interface MailProps {
	_id?: string | null;
	subject?: string | null;
	body?: { type: string; children: { text: string }[] } | null;
	status?: number | null;
	recipients?: {
		contacts: {
			_id: string | null;
			label: string | null;
		}[];
		users?: { _id: string | null; label: string | null }[];
	};
	origin?: {
		// who sent the email
		_id: string | null;
		name: {
			first: string | null;
			last: string | null;
		};
	};
}

// interface MailSendProps {
// 	_id?: string | null;
// 	recipients?: string[];
// 	subject?: string | null;
// 	body?: string | null;
// 	from?: string | null;
// }

export interface MailEditProps {
	_id: string;
	action: (state: number, id: string) => void;
	alertProps: (visibility: boolean, style: string, text: string) => void;
}

const MailEdit: React.FC = () => {
	// Create a Slate editor object that won't change across renders.
	const [editor] = useState(() => withReact(createEditor()));
	const [mailData, setMailData] = useState<MailProps | undefined>({
		_id: null,
		subject: '',
		body: null,
		status: 1, // Not started
		recipients: {
			contacts: [],
			users: [],
		},
	});
	const [submitLock, setSubmitLock] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(true);
	const [contactsList, setContactsList] = useState<SelectOption[] | undefined>();
	const viewState = useSelector((state: RootState) => state.example.value);
	const dispatch = useDispatch();
	// const [usersList, setUsersList] = useState<JSX.Element>();
	const pageTitle = useRef('Send an email');
	const toast = useToast();
	useEffect(() => {
		async function fetchObjects() {
			const contactsOptions = await getAllContactsArray(mailData?.recipients?.contacts);
			setContactsList(contactsOptions);
		}
		fetchObjects();
	}, []);

	const initialValue: CustomElement[] = useMemo(
		() => [
			{
				type: 'h1',
				align: 'center',
				children: [{ text: 'Heading!', bold: true, underline: true }],
			},
			{
				type: 'paragraph',
				children: [{ text: 'A line of text in a paragraph.' }],
			},
			{
				type: 'paragraph',
				children: [{ text: 'Here are the ' }, { text: 'code changes', code: true }, { text: ' required' }],
			},
			{
				type: 'blockquote',
				children: [{ text: 'Here is the quote requested' }],
			},
		],
		[]
	);

	const handleSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>) => {
			const form = e.currentTarget;
			setSubmitLock(true);
			e.preventDefault();

			if (!form.checkValidity() || mailData === undefined) {
				const toastId = toast.open(
					<div className=" flex gap-2 bg-red-300 text-red-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Missing Fields!</p>
							<p>Please complete all required fields.</p>
						</div>
					</div>,
					5000
				);
			} else {
				// TODO - Allow deleting etc
				await newMailerObject(mailData, toast);
			}
			setSubmitLock(false);
			form.classList.add('was-validated');
			console.log(mailData);
		},
		[mailData, toast]
	);

	return (
		<Modal
			show={true}
			className="max-w-[75%] h-[80dvh] max-h-[92dvh]"
			title={pageTitle.current}
			onClose={() => dispatch(update({ state: STATE_DASHBOARD, _id: '' }))}
			btn1={() => dispatch(update({ state: STATE_DASHBOARD, _id: '' }))}
		>
			<Slate
				editor={editor}
				initialValue={initialValue}
				onChange={(event) => {
					const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
					if (isAstChange) {
						const content = JSON.stringify(event);
						setMailData((prevMailData: MailProps | undefined) => {
							let updatedData = { ...prevMailData };
							updatedData['body'] = JSON.parse(content);
							console.log(mailData?.body);
							return updatedData;
						});
					}
				}}
			>
				<div className="">
					{/* <h3>{mailData?.name?.first ? 'Edit ' + mailData?.name.first : 'Add a contact'}</h3> */}
					{/* <h3>{pageTitle.current}</h3> */}
					<div className="flex gap-2 items-stretch">
						{/* <div className="m-3 p-2 bg-zinc-50 dark:bg-dark-600 rounded-lg w-full border-neutral-200 border-[1px]"> */}
						<form
							className="row g-3 needs-validation flex flex-wrap flex-row "
							method="post"
							noValidate
							onSubmit={handleSubmit}
						>
							<div className=" w-1/2 px-2">
								<label htmlFor="contacts" className="form-label form-control-label-sm required">
									Recipients
								</label>
								{/* TODO - auto select contacts if a _id is passed */}
								<Select
									multiple
									value={mailData?.recipients?.contacts}
									onChange={(e) => {
										handleOnChange(e, ['recipients', 'contacts'], setMailData);
									}}
									options={contactsList}
								/>
								<p className="small_help">Recipients will not see each others details.</p>
							</div>
							<div className=" w-1/2 px-2">
								<label htmlFor="subject" className="form-label form-control-label-sm required">
									Subject
								</label>
								<input
									type="text"
									value={mailData?.subject || undefined}
									onChange={(e) => {
										handleOnChange(e, ['subject'], setMailData);
									}}
									className="form-control form-control-sm"
									id="subject"
									autoFocus
									required
									autoComplete="off"
									data-lpignore="true"
								/>
							</div>
							<div className=" w-full px-2 pt-3 my-4">
								<label htmlFor="body" className="form-label form-control-label-sm">
									Message
								</label>
								<RichEditor.drawEditor editor={editor} classes="" />
							</div>
							{/* TODO - Show preview of what the email will look like with styling (global email styles/borders/containers etc) */}
							{/* <div className=" w-full px-2"> */}
							{/* HTML color picker element to choose specific colors 0*/}
							{/* <label className="form-label form-control-label-sm">Preview</label>
								<div className=" p-1 bg-zinc-200 border-gray-400 border-opacity-25  border-8">{mailData?.body}</div>
								<p className="small_help">Styles may vary depending on the users mail client and screen resolution.</p> */}
							{/* </div> */}
							<button type="submit" className="bg-emerald-500 hover:bg-emerald-600 absolute bottom-0" disabled={submitLock}>
								{submitLock ? 'Loading...' : 'Send'}
							</button>

							{/* <button type="button" onClick={() => action(STATE_DASHBOARD, '')} className=" bg-red-500 hover:bg-red-600">
								Back
							</button> */}
						</form>
						{/* </div> */}
					</div>
				</div>
			</Slate>
		</Modal>
	);
};

export default MailEdit;
