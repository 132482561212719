import { useDispatch } from 'react-redux';
import Contacts, { Contact } from '../../../api/Contacts';
import { STATE_DASHBOARD } from '../../../functions/Constants';
import handleOnChange from '../../../functions/handleOnChange';
import { update } from '../../../state/viewSlice';
import handleSubmit from '../../../functions/handleSubmit';
import { useCallback } from 'react';

export default ({ dataState }: { dataState: [Contact, React.Dispatch<React.SetStateAction<Contact>>] }) => {
	// Use redux to pass data down to components
	const [data, setData] = dataState;
	// const viewState = useSelector((state: RootState) => state.example.defaultValue);
	const dispatch = useDispatch();

	const handleSubmitWrapper = useCallback(
		() =>
			handleSubmit(undefined, [data, setData], {
				post: Contacts.post, // Only `postById` is used
				put: Contacts.put,
				patch: Contacts.patch,
			}),
		[data, setData]
	);

	return (
		<>
			<div className=" gap-3 px-2 flex flex-wrap flex-row">
				{/* <div className="m-3 p-2 bg-zinc-50 dark:bg-dark-600 rounded-lg w-full border-neutral-200 border-[1px]">
					<form className="row g-3 needs-validation flex flex-wrap flex-row" method="post" noValidate> */}
				<div className="w-1/3 px-2">
					<label htmlFor="title" className="form-label form-control-label-sm required">
						Title
					</label>
					<input
						type="text"
						defaultValue={data && 'title' in data ? data.title : ''}
						onChange={(e) => {
							handleOnChange(e, ['title'], setData);
						}}
						className="form-control form-control-sm"
						id="title"
						autoFocus
						required
					/>
					{/* <p className="small_help">Used for [business_FIRST_NAME] smartfields. </p>
					<div className="invalid-feedback">Please add a first name</div> */}
				</div>
				<div className="w-1/4 px-2">
					<label htmlFor="business_website" className="form-label form-control-label-sm">
						Website
					</label>
					<input
						type="text"
						defaultValue={data && 'website' in data ? data.website : ''}
						onChange={(e) => {
							handleOnChange(e, ['website'], setData);
						}}
						className="form-control form-control-sm"
						id="website"
					/>
					{/* <p className="small_help">Used for [business_website] smartfields. </p> */}
				</div>
				<div className="w-1/4 px-2">
					<label htmlFor="email" className="form-label form-control-label-sm required">
						Email
					</label>
					<input
						type="email"
						defaultValue={data?.email}
						onChange={(e) => {
							handleOnChange(e, ['email'], setData);
						}}
						className="form-control form-control-sm"
						id="email"
						required
					/>
					{/* <div className="invalid-feedback">Please enter a valid email address</div> */}
				</div>
				<div className="w-1/4 px-2">
					<label htmlFor="business_phone" className="form-label form-control-label-sm">
						Phone
					</label>
					<input
						type="text"
						defaultValue={data?.mobile}
						onChange={(e) => {
							handleOnChange(e, ['mobile'], setData);
						}}
						className="form-control form-control-sm"
						id="mobile"
					/>
				</div>
				<div className="w-1/6 px-2">
					<label htmlFor="relationship" className="form-label form-control-label-sm">
						Relationship
					</label>
					<select
						id="relationship"
						className="form-select form-select-sm"
						// defaultValue={data.relationship}
						onChange={(e) => {
							handleOnChange(e, ['relationship'], setData);
						}}
					>
						<option value={0} selected={data.relationship === undefined}>
							None
						</option>
						<option value={1} selected={data.relationship === 1}>
							Lead
						</option>
						<option value={2} selected={data.relationship === 2}>
							Customer
						</option>
						<option value={3} selected={data.relationship === 3}>
							Connection
						</option>
					</select>
				</div>
				<div className="w-full flex flex-wrap flex-row">
					{' '}
					{/* Address section */}
					<div className="w-1/6 px-2">
						<label htmlFor="inputAddress" className="form-label form-control-label-sm">
							Country
						</label>
						<input
							type="text"
							defaultValue={data?.address?.country}
							onChange={(e) => {
								handleOnChange(e, ['address', 'country'], setData);
							}}
							className="form-control form-control-sm"
							id="inputAddress"
							placeholder="Australia"
						/>
					</div>
					<div className="w-1/6 px-2">
						<label htmlFor="inputAddress" className="form-label form-control-label-sm">
							Address
						</label>
						<input
							type="text"
							defaultValue={data?.address?.street}
							onChange={(e) => {
								handleOnChange(e, ['address', 'street'], setData);
							}}
							className="form-control form-control-sm"
							id="inputAddress"
							placeholder="1234 Main St"
						/>
					</div>
					{/* <div className="w-1/6 px-2">
                        <label htmlFor="inputAddress2" className="form-label form-control-label-sm">
                            Address 2
                        </label>
                        <input
                            type="text"
                            defaultValue={data?.business_address_2}
                            onChange={(e) => {
                                handleOnChange(e, ['business_address_2'], setData);
                            }}
                            className="form-control form-control-sm"
                            id="inputAddress2"
                            placeholder="Apartment, studio, or floor"
                        />
                    </div> */}
					<div className="w-1/6 px-2">
						<label htmlFor="inputCity" className="form-label form-control-label-sm">
							City
						</label>
						<input
							type="text"
							defaultValue={data?.address?.city}
							onChange={(e) => {
								handleOnChange(e, ['address', 'city'], setData);
							}}
							className="form-control form-control-sm"
							id="inputCity"
						/>
					</div>
					<div className="w-1/6 px-2">
						<label htmlFor="inputState" className="form-label form-control-label-sm">
							State
						</label>
						<input
							type="text"
							defaultValue={data?.address?.state}
							onChange={(e) => {
								handleOnChange(e, ['address', 'state'], setData);
							}}
							className="form-control form-control-sm"
							id="inputState"
						/>
						{/* <select id="inputState" className="form-select form-select-sm">
                    <option selected disabled defaultValue={0}>Choose...</option>
                    <option defaultValue={0}>ACT</option>
                    <option defaultValue={1}>NSW</option>
                    <option defaultValue={2}>NT</option>
                    <option defaultValue={3}>QLD</option>
                    <option defaultValue={4}>SA</option>
                    <option defaultValue={5}>TAS</option>
                    <option defaultValue={6}>VIC</option>
                    <option defaultValue={7}>WA</option>
                    </select> */}
					</div>
					<div className="w-1/6 px-2">
						<label htmlFor="inputZip" className="form-label form-control-label-sm">
							Postcode
						</label>
						<input
							type="text"
							defaultValue={data?.address?.postcode}
							onChange={(e) => {
								handleOnChange(e, ['address', 'postcode'], setData);
							}}
							className="form-control form-control-sm"
							id="inputZip"
						/>
					</div>
				</div>
				<div className=" w-full px-2">
					<label htmlFor="business_notes" className="form-label form-control-label-sm">
						Summary
					</label>

					<textarea
						// defaultValue={dataData?.business_notes}
						rows={3}
						// onChange={(e) => {
						// 	handleOnChange(e, 'business_notes', setDataData);
						// }}
						className="form-control form-control-sm"
						// id="business_notes"
					></textarea>
					<p className="small_help">
						Private note only users in your company can see. This message will be displayed as a reminder of who your client is.
					</p>
				</div>
				<div className="w-full flex">
					<div className="col-1">
						<button type="button" className=" bg-emerald-500 hover:bg-emerald-600" onClick={handleSubmitWrapper}>
							{data && 'title' in data && data.title ? 'Save ' : 'Add'}
						</button>
					</div>
					<button type="button" className="bg-amber-500 hover:bg-amber-600 w-fit px-4">
						Save & Create a new Contact
					</button>
					<div className="col-1">
						<button
							type="button"
							onClick={() => dispatch(update({ state: STATE_DASHBOARD, _id: '' }))}
							className=" bg-red-500 hover:bg-red-600"
						>
							Back
						</button>
					</div>
				</div>
			</div>
		</>
	);
};
