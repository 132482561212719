import axios from 'axios';
import { API_ENDPOINT, Project } from '../functions/Constants';
import { catchError } from '../functions/handleLoginAuth';

const API_ENDPOINT_PROJECTS = API_ENDPOINT + '/project';

/**
 * Projects API endpoint
 */
const Projects = {
	/**
	 * Get a single object based on an id.
	 *
	 * @param   project String or object used as an id
	 * @param   setData Set Function used to update an object
	 */
	async get(
		setData: React.Dispatch<React.SetStateAction<Project>> | React.Dispatch<React.SetStateAction<Project[] | null>>,
		query?: string
	) {
		try {
			const response = await axios.get(
				API_ENDPOINT_PROJECTS +
					// `/${project && typeof project === 'string' ? project : project && typeof project === 'object' && project._id}` +
					`${query ? '?' + query : ''}`,
				{
					withCredentials: true,
				}
			);
			if (response.data) {
				setData(response.data);
			}
		} catch (error) {
			catchError(error);
		}
	},

	/**
	 * Returns all active records
	 *
	 * @returns Array containing basic details from all relevant records
	 */
	async list() {
		try {
			const response = await axios.get(API_ENDPOINT_PROJECTS + `/list`, { withCredentials: true });
			if (response.data) {
				return response.data;
			}
		} catch (error) {
			catchError(error);
			return [];
		}
	},

	/**
	 * Returns all records, including those marked as deleted
	 *
	 * @returns Array containing basic details from all relevant records
	 */
	async listAll(): Promise<Project[] | undefined> {
		try {
			const response = await axios.get(API_ENDPOINT_PROJECTS + `/list`, { withCredentials: true });
			if (response.data) {
				return response.data;
			}
		} catch (error) {
			catchError(error);
			return [];
		}
	},

	/**
	 * Get a single object based on an id.
	 *
	 * @param   _id String or object used as an id
	 * @param   setData Set Function used to update an object
	 * @alias this.get()
	 */
	async getByContact(_id: string, setData: React.Dispatch<React.SetStateAction<Project>>) {
		this.get(setData);
	},

	/**
	 * Get a single object based on an id.
	 *
	 * @param   _id String or object used as an id
	 * @param   setData Set Function used to update an object
	 * @alias this.get()
	 */
	async findById(_id: string, setData: React.Dispatch<React.SetStateAction<Project>>) {
		this.get(setData);
	},

	/**
	 * Create a new record in the database
	 *
	 * @param   contact Object used
	 */
	async post(project: Project, toast?: any) {
		try {
			await axios.post(API_ENDPOINT_PROJECTS, { ...project }, { withCredentials: true });
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Update a record in the database
	 *
	 * @param   contact Object used
	 */
	async put(project: Project, toast?: any) {
		try {
			await axios.put(API_ENDPOINT_PROJECTS + `/${project._id}`, { ...project }, { withCredentials: true });
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Update partial details of a record in the database
	 *
	 * @param   contact Object used
	 */
	async patch(project: Project, toast?: any) {
		this.put(project, toast);
	},
};

export default Projects;
